import * as React from "react"
import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <Layout pageTitle="tec-craft">
      <h3>Technology. Engineering. Consulting</h3>
      <p>This page is growing in conjunction with my React skills</p>
    </Layout>
  )
}

export default IndexPage
